@font-face {
  font-family: 'roboto';
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-Regular.eot');
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-Regular.woff') format('woff'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-Italic.eot');
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-Italic.woff') format('woff'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-Light.eot');
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-Light.woff') format('woff'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-LightItalic.eot');
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-LightItalic.woff') format('woff'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-Bold.eot');
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-Bold.woff') format('woff'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-BoldItalic.eot');
  src: url('/sites/all/themes/custom/achmea/fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-BoldItalic.woff') format('woff'),
  url('/sites/all/themes/custom/achmea/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
// Colors.
$color-primary: #7eb3b1;
$color-white: #fff;
$color-black: #575757;
$color-gray: #777777;
$color-gray-light: #f7f7f7;
$color-orange: #F7B74D;

// Global settings.
$global-padding: 1rem;
$global-margin: 1rem;

// Fonts.
$font-size-regular: 14px;
$font-size-big: 16px;
$font-size-heading: 26px;

// Border.
$border: 1px solid lighten($color-gray, 15%);
$border-bold: 3px solid $color-primary;
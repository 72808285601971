.views-accordion {
  cursor: pointer;
  
  .view-grouping-header.description {
    @include roboto(16px);
    font-weight: 300;
    font-style: normal;
    padding: 20px 0;
  }
  
  h3 {
    @include roboto-light(26px);
    position: relative;
    counter-reset: faq-items;
    padding: 10px 90px 10px 98px;
    margin: 0 -99px;
    border-bottom: $border;
    transition: background-color 1s;
    
    & + .views-row {
      border-top: $border;
    }
    
    &:hover {
      background-color: rgba(241, 241, 241, 0.34);
      transition: all 0.3s ease-in-out;
    }
    
    &:after {
      position: absolute;
      display: block;
      content: '';
      width: 64px;
      height: 64px;
      top: -5px;
      right: 15px;
      background: url('../images/arrow_down.svg');
      transition: all 0.5s;
      
      @media (max-width: 768px) {
        right: -25px;
      }
    }
    
    &.closed {
      &:after {
        transform: rotate(0deg);
      }
    }
    
    &.open {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  
  .views-row {
    margin-bottom: 20px;
    background-color: $color-white;
    transition: background-color 1s;
    
    .views-field-nid {
      display: none;
    }
    
    .views-field-title {
      @include roboto-light(16px);
      clear: both;
      
      &:before {
        @include roboto(16px);
        position: absolute;
        top: 6px;
        left: 100px;
        content: counter(faq-items) '.';
        counter-increment: faq-items;
        width: 30px;
        height: 30px;
        display: inline-block;
        line-height: 30px;
        text-align: center;
        margin-right: 10px;
      }
    }
    
    &:hover,
    &.open {
      transition: all 0.3s ease-in-out;
      background-color: rgba(241, 241, 241, 0.34);
    }
    
    .views-field-body {
      @include roboto(16px);
      font-weight: 300;
      opacity: 0;
      transition: all 0.5s ease-in-out;
    }
    
    &.closed,
    &.open {
      overflow: hidden;
      border-bottom: $border;
      padding: 10px 90px 10px 150px;
      position: relative;
      margin: 0 -40px 0 -99px;
      
      @media (min-width: 768px) {
        margin: 0 -99px;
      }
      
      .views-field-body {
        margin-top: 15px;
        margin-left: 25px;
      }
      
      &:after {
        position: absolute;
        display: block;
        content: '';
        width: 64px;
        height: 64px;
        top: -10px;
        right: 15px;
        background: url('../images/arrow_down.svg');
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        transition: all 0.5s;
      }
    }
    
    &.open {
      .views-field-body {
        max-height: 10000px;
        opacity: 1;
      }
      
      &:after {
        transform: rotate(180deg);
      }
    }
    
    &.closed {
      .views-field-body {
        max-height: 0;
        opacity: 0;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  
  .views-row.open:last-child,
  .views-row.closed:last-child {
    border-bottom: $border;
  }
  .view-footer {
    .grippie {
      display: none;
    }
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    
    h2 {
      font-size: 18px;
      margin-bottom: 20px;
    }
    
    .form-control {
      height: 120px;
    }
    
    .webform-client-form > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      
      .form-item {
        min-width: 100%;
        margin-bottom: 10px;
        
        label {
          top: 8px;
          left: 10px;
        }
        
        input,
        textarea {
          border-radius: 0;
          box-shadow: none;
          padding: 5px 10px;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          border: $border;
          
          &:focus {
            outline: 0;
            border-color: lighten($color-gray, 15%);
          }
        }
        
        textarea {
          resize: none;
        }
      }
    }
    label {
      font-weight: 200;
      color: $color-gray-light;
    }
    
    .webform-button-wrapper {
      margin-top: 0;
      margin-left: auto;
      
      p {
        display: inline-block;
        margin-top: -25px;
        font-family: 'roboto';
        font-weight: 300;
        font-size: 16px;
        vertical-align: middle;
      }
      
      .webform-button-svg {
        transform: scale(0.7, 0.7);
        display: inline-block;
      }
    }
  }
}
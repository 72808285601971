.page-pbs {
  .main-container {
    @include roboto;
    color: $color-black;
    background-color: $color-white;

    .page-header {
      color: $color-black;
    }
  }
}

#main-content {
  body.page-pbs:not(.page-pbs-wist-u-dat) & {
    margin-top: 0 !important;
    padding-top: $global-padding !important;
  }
}
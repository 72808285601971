.pbs-visual {
  position: relative;
  
  &.left-visual {
    @media (min-width: 768px) {
      height: 100%;
    }
  }
  
  > .wrapper {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    > .image {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    
    > .info {
      position: absolute;
      display: block;
      left: 10%;
      bottom: 10%;
      width: auto;
      max-width: 60%;
      color: $color-white;
      
      > .title {
        @include roboto($font-size-heading);
        margin-bottom: $global-margin;
      }
      
      > .body {
        @include roboto-light($font-size-regular);
      }
    }
  }
}

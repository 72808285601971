.pbs-dashboard > .chart-wrapper {
  padding: 0 0 0 0;
  margin: 0 0 ($global-margin * 4);
  border-top: $border-bold;
  border-bottom: $border-bold;

  > .main-content {
    display: flex;
    justify-content: center;
    align-items: stretch;

    @include medium-device-and-down {
      flex-direction: column;
    }

    > .chart {
      flex: 3;
      min-height:400px;
      width: 100%;
      height: 100%;

      @include medium-device-and-down {
        border-bottom: $border-bold;
      }
    }

    > .legend {
      display: flex;
      flex: 5;
      flex-direction: column;
      justify-content: center;
      margin-left: $global-margin * 5;

      @include large-device-and-down {
        padding: ($global-padding * 5) 0;
        margin-left: 0;
      }

      > .stroke {
        display: none;
      }

      > .filter {
        display: none;
      }

      > .legend-item {
        display: flex;
        align-items: center;
        padding: $global-padding;
        font-size: $font-size-big;
        border-top: $border;
        background-color: $color-white;

        @include extra-small-device {
          display: block;
        }

        &:last-child {
          border-bottom: $border;
        }

        &:hover,
        &.active {
          cursor: pointer;
          background-color: $color-gray-light;

          > .label,
          > .values {
            @include roboto-bold;
          }
        }

        > .indicator {
          display: block;
          min-width: 15px;
          width: 15px;
          height: 15px;
          margin-right: $global-margin;
          border-radius: 50%;
          background-color: $color-black;

          @include extra-small-device {
            display: inline-block;
          }
        }

        > .label {
          @include roboto;
          color: $color-black;
          font-size: $font-size-big;
          padding: 0;
          margin: 0;

          @include extra-small-device {
            display: inline-block;
          }
        }

        > .values {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;

          @include extra-small-device {
            display: block;
            margin-top: 5px;
          }

          > p {
            color: $color-black;
            text-align: right;
            width: 100px;
            margin: 0 $global-margin 0 0;

            @include extra-small-device {
              text-align: left;
              width: 100%;
            }

            &:last-child {
              margin-right: 0;

              @include extra-small-device {
                margin: 0;
              }
            }

            &.employee,
            &.employer {
              display: none;
            }
          }
        }
      }
    }
  }

  > .description {
    display: flex;
    justify-content: center;

    > .chart-description {
      flex: 3;

      @include medium-device-and-down {
        display: none;
      }
    }

    > .legend-description {
      display: flex;
      flex: 5;
      align-items: center;
      padding: 0 $global-padding;
      margin-left: $global-margin * 5;

      @include medium-device-and-down {
        margin-left: 0;
      }

      > .label {
        @include roboto-bold;
        color: $color-black;
        font-size: $font-size-big;
        padding: 0;
        margin: 0;
      }

      > .value {
        @include roboto-bold;
        color: $color-black;
        font-size: $font-size-big;
        margin-left: auto;
      }
    }
  }

  &.expanded-view {
    padding-top: $global-padding * 4;

    @include large-device-and-down {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }

    > .main-content {
      @include large-device-and-down {
        flex-direction: column;
      }

      > .chart {
        flex: 1;
        border-bottom: 0;
      }

      > .legend {
        flex: 4;
        border-bottom: 0;

        @include medium-device-and-down {
          margin-top: 0;
        }

        > .stroke {
          display: block;
          width: 100%;
          height: 3px;
          margin-top: $global-margin * 2;
          background-color: $color-primary;
        }

        > .filter {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: $global-padding 0;
          margin-bottom: $global-margin;

          &:after {
            z-index: 1;
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: $color-primary;
          }

          > .button {
            @include roboto-bold;
            z-index: 2;
            color: $color-black;
            width: 100px;
            height: 30px;
            margin-right: $global-margin;
            background-color: $color-white;
            border: $border;
            border-radius: 0;

            &:last-child {
              margin-right: $global-margin * 2;
            }

            @include extra-small-device {
              &:last-child {
                margin-right: 0;
              }
            }

            &:hover,
            &.active {
              color: $color-white;
              background-color: $color-primary;
              border-color: $color-primary;
            }
          }
        }

        > .legend-item {
          padding-right: $global-padding * 2;

          &:nth-child(2) {
            border-top: 0;
          }

          &:last-child {
            border-bottom: 0;
          }

          @include large-device-and-down {
            display: block;

            > .indicator,
            > .label {
              display: inline-block;
            }

            > .values {
              display: block;
              margin-top: 5px;

              > p {
                text-align: left;
                width: 100%;

                &:last-child {
                  margin: 0;
                }
              }
            }
          }

          > .values {
            > .employee,
            > .employer {
              display: block;
            }

            > p {
              &.active {
                color: $color-primary;
              }
            }
          }
        }
      }
    }

    > .description {
      display: none;
    }
  }
}

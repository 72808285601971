.pbs-dashboard {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 6.5rem;

    .profile {
      display: flex;
      align-items: center;

      .picture {
        display: inline-block;
        margin-right: $global-margin * 2;
        //overflow: hidden;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      .name {
        @include roboto-light(18px);
        font-weight: 400;
      }
    }

    .link {
      margin-left: auto;

      a {
        display: block;
        height: 35px;
        line-height: 35px;
        padding: 0 $global-padding;
        color: $color-black;
        border: 1px solid darken($color-gray-light, 10%);
        border-radius: 2px;
        transition: all 0.2s ease-in-out;

        .orange {
          color: $color-orange;
        }

        &:hover {
          color: $color-black;
          text-decoration: none;
          border-color: $color-orange;

          .orange {
            color: $color-orange;
          }
        }
      }
    }
  }

  .page-header {
    @include roboto-light;
  }

  .description {
    @include roboto-light;
    color: $color-gray;
  }
}
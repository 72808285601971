.filter-wrapper {
  position: relative;
  //display: flex;
  //justify-content: flex-end;

  &.active {
    .filters {
      display: flex;
    }
  }

  .filter-toggle-btn {
    display: block;
    width: 28px;
    height: 28px;
    margin-bottom: $global-margin;
    background-image: url('../images/filter.png');
    background-size: 28px;
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }

  .close-btn {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    right: 5px;
    width: 40px;
    height: 40px;
    background-image: url('../images/close_zwart.svg');
    background-size: 40px;
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }

  .filters {
    z-index: 999;
    position: absolute;
    display: none;
    width: 330px;
    padding: $global-padding;
    background-color: $color-white;
    border: $border;
    border-radius: 0 !important;

    > form {
      display: flex;
      flex-direction: column;
      margin-right: $global-margin;

      &:last-child {
        margin-right: 0;
      }

      > .filter {
        display: flex;
        align-items: center;
        margin-bottom: $global-margin;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          white-space: nowrap;
          @include roboto;
          color: $color-gray;
          margin-left: $global-margin;
        }
      }
    }

    input[type='radio'] {
      display: none;

      + label {
        text-align: center;
        content: '';
        visibility: hidden;
        position: relative;
        width: 20px;
        height: 20px;
        margin: 0;

        &:before {
          content: '';
          visibility: visible;
          position: absolute;
          top: 3px;
          right: 0;
          bottom: 0;
          left: 3px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }

        &:after {
          content: '';
          visibility: visible;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 1px solid $color-gray;
          border-radius: 50%;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &:checked + label:before {
        background-color: $color-primary;
      }

      // IE11.
      @media all and (-ms-high-contrast: none) {
        display: block;
        width: 100%;

        + label {
          display: none;
        }
      }
    }
  }
}

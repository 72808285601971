.info-wrapper {
  table.category-content {
    thead {
      tr {
        th {
          @include roboto;
          font-size: $font-size-regular;
          font-weight: normal;
          border: 0;

          &.category-label {
            font-size: $font-size-heading;
          }
        }
      }
    }

    tbody {
      font-size: $font-size-big;

      tr {
        border-top: $border;

        &.footable-header {
          display: none;
        }

        &:last-child {
          border-bottom: $border;
        }

        &.expanded {
          > th,
          > td {
            &.subcategory-label {
              @include roboto-bold;
              color: $color-primary;
            }

            > .footable-toggle {
              opacity: 1;
              color: $color-white;
              background-color: $color-primary;
              border-color: $color-primary;
            }
          }
        }

        &.footable-detail-row {
          .footable-details {
            display: block !important;
            padding: $global-padding;

            tbody {
              tr {
                border: 0;

                > th:first-of-type {
                  display: none;
                }

                td {
                  word-break: normal;
                }
              }
            }
          }
        }

        > th,
        > td {
          @include roboto;
          border: 0;

          &.subcategory-label {
            width: 30%;
          }

          > .footable-toggle {
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size-regular;
            width: 18px;
            height: 18px;
            border: 2px solid $color-gray;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;

            &:before {
              @include roboto-bold;
              content: 'i';
            }

            &:hover {
              cursor: pointer;
              opacity: 1;
              color: $color-white;
              background-color: $color-primary;
              border-color: $color-primary;
            }
          }
        }
      }
    }

    .series-label,
    .series-value {
      text-align: right;

      &.show {
        display: table-cell !important;
      }
    }

    .series-value {
      width: 15%;
    }
  }

  &.expanded-view table.category-content {
    .series-label,
    .series-value {
      display: none !important;

      &.show {
        display: table-cell !important;
      }
    }

    .series-label {
      &.show {
        display: flex !important;
        flex-direction: column;
        width: 100%;
        font-size: 0;

        &:after {
          content: attr(data-total);
          font-size: $font-size-heading;
        }
      }
    }

    .series-value {
      width: 45%;
    }
  }
}

.accordion-search-wrapper {
  position: relative;
  
  .accordion-search-box {
    @include roboto-light(16px);
    min-width: 100%;
    width: 100%;
    max-width: none;
    margin: 0 0 15px;
    padding: 10px 0;
    background-color: transparent;
    border: 0;
    border-bottom: 3px solid $color-primary;
    
    &:focus,
    &:active {
      outline: none;
    }
  }
  
  .glyphicon-search {
    position: absolute;
    top: 15px;
    right: 10px;
  }
}
@mixin roboto-light($size:null) {
  font-family: roboto;
  font-weight: 300;
  font-size: $size;
  font-style: normal;
}

@mixin roboto-light-italic($size:null) {
  font-family: roboto;
  font-weight: 300;
  font-size: $size;
  font-style: italic;
}

@mixin roboto($size:null) {
  font-family: roboto;
  font-weight: 400;
  font-size: $size;
  font-style: normal;
}

@mixin roboto-italic($size:null) {
  font-family: roboto;
  font-weight: 400;
  font-size: $size;
  font-style: italic;
}

@mixin roboto-bold($size:null) {
  font-family: roboto;
  font-weight: 700;
  font-size: $size;
  font-style: normal;
}

@mixin roboto-bold-italic($size:null) {
  font-family: roboto;
  font-weight: 700;
  font-size: $size;
  font-style: italic;
}

/* Large Devices, Wide Screens */
@mixin large-device {
  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    @content;
  }
}

@mixin large-device-and-down {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

/* Medium Devices, Desktops */
@mixin medium-device {
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    @content;
  }
}

@mixin medium-device-and-down {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

/* Small Devices, Tablets */
@mixin small-device {
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    @content;
  }
}

/* Extra Small Devices, Phones */
@mixin extra-small-device {
  @media only screen and (max-width: 480px) {
    @content;
  }
}